<template>
    <section class="section-imagenes-component">
        <div class="row mx-0">
            <div class="col px-0 carousel-container">
                <template v-if="tipoSlider">
                    <VueSlickCarousel
                    :style="`height:${containerSliderHeight + 9}px`"
                    :arrows="true"
                    :dots="true"
                    :infinite="true"
                    :slides-to-show="1"
                    :slides-to-scroll="1"
                    :variable-width="true"
                    :autoplay="true"
                    :autoplay-speed="5000"
                    >
                        <div v-for="(item, index) in imagenes" :key="index" :style="`height:${containerSliderHeight}px;`">
                            <img :src="item.imagen" alt="" class="img-carousel br-12" :style="`height:${containerSliderHeight}px;max-width: calc(100vw - 120px);`" @click="redireccionImagen(item)" />
                        </div>
                    </VueSlickCarousel>
                </template>
                <template v-else>
                    <div class="d-none d-sm-flex flex-wrap ">
                        <div v-for="(i, idx) in imagenes" :key="`fijo-${idx}`" :style="`height:${containerSliderHeight}px;`" class="br-12 m-2">
                            <img :src="i.imagen" class="br-12 cr-pointer" :style="`height:${containerSliderHeight}px;max-width: calc(100vw - 120px);`" @click="redireccionImagen(i)" />
                        </div>
                    </div>
                    <div class="d-flex d-md-none flex-wrap">
                        <img v-for="(i, idx) in imagenes" :key="`fijo-${idx}`" :src="i.imagen" class="br-12 cr-pointer w-100 my-2" @click="redireccionImagen(i)" />
                    </div>
                </template>
            </div>
        </div>
        <modal-producto
        ref="modalProducto"
        :producto="producto"
        :promocion="promocion"
        @eliminar="eliminarProductoCarrito"
        @agregar="agregarProductoCarrito"
        @update="cambiarCantidad"
        />
        <modal-cupon ref="modalCupon" @detalleCupon="modalCuponDetalle" />
        <modal-detalle-cupon ref="modalDetalleCupon" :detalle-cupon="cuponDetalle" @irAtras="modalCupones" />
        <invitado-modal-registrarse ref="modalRegistrarse" />
    </section>
</template>
<script>
import Carrito from '~/services/carrito/carrito'
/** Slick-carousel */
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapGetters } from 'vuex'
/** Slick-carousel */
export default {
    components:{
        VueSlickCarousel,
        modalCupon: () => import('../../cupones/partials/modalCupon.vue'),
        modalDetalleCupon: () => import('../../cupones/partials/modalDetalleCupon'),
    },
    props: {
        data:{
            type:Object,
            default:() => {}
        },
    },
    data(){
        return {
            producto:{},
            promocion:{},
            cuponDetalle: {},
            windowWidth: screen.width,
            containerSliderHeight:0

        }
    },
    computed:{
        ...mapGetters({
            isInvitado:'home/isInvitado'
        }),
        imagenes(){
            return this.data.elementos.imagenes
        },
        tipoSlider(){
            if(this.data.tipo == 11){
                return true
            }
            return false
        },
        altoMaximo(){
            return this.data.parametro
        }
    },
    mounted(){
        window.addEventListener("resize", this.getWidthSetHeight);
        this.getWidthSetHeight()
    },
    methods:{
        getWidthSetHeight(){
            let windowScreen = window.innerWidth;
            this.containerSliderHeight = (windowScreen*this.altoMaximo)/100
        },
        redireccionImagen(item){
            switch (item.redireccion){
            case 12:
                this.imagenTipoProducto(item) 
                break;
            case 11:
            case 13:
            case 21:
            case 22:
                this.openLink(item) 
                break;
            case 23:
                this.modalCupones()  
                break;
            case 24:
                this.openLinkExterno(item)    
                break;
            
            default:
                break;
            }
            this.guardarClick(item.id_elemento)
        },
        imagenTipoProducto(item){
            if(item.producto == null) return

            if(this.isInvitado){
                this.$router.push({name:'invitado'})
                return
            }

            this.producto = item.producto
            this.promocion = item.data_promocion

            this.$refs.modalProducto.toggle()
        },
        openLinkExterno(item){
            window.open(item.link);
        },
        openLink(item){
            if(item.link === null) return

            if(this.isInvitado){
                if(item.new_tab){
                    let ruta = this.$router.resolve({path:'/invitado'+item.link});
                    window.open(ruta.href, '_blank');
                }else{
                    this.$router.push({path:'/invitado'+item.link})
                }
                return
            }

            if(item.new_tab){
                let ruta = this.$router.resolve({path:item.link});
                window.open(ruta.href, '_blank');
            }else{
                this.$router.push({path:item.link})
            }
        },
        modalCupones(){
            if(this.isInvitado){
                this.$refs.modalRegistrarse.toggle()
                return
            }
            this.$refs.modalCupon.toggle()
        },
        modalCuponDetalle(item){
            this.cuponDetalle = item
            this.$refs.modalDetalleCupon.toggle();
        },
        async guardarClick(idElemento){
            this.$store.dispatch('home/guardarClick',idElemento)
        },
        async agregarProductoCarrito(){
            try {
                if (this.$usuario.edad < 18){
                    return this.abrirModalEdad();
                }
                const form = {idProducto:this.producto.id_producto}
                const {data} = await Carrito.addProducto(form)
                this.producto.cant_carrito = data.producto.cantidad
                this.producto.total_carrito = data.producto.total_final
                this.notificacion('','Producto agregado al carrito','success')

                this.$usuario.cant_productos_carrito = data.producto.productos_en_carrito;
            } catch (error){
                this.errorCatch(error)
            }
        },
        cambiarCantidad(cantidad){
            if(this.producto.compra_maxima != 0){
                if(cantidad > this.producto.compra_maxima){
                    return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto. Permitido '+this.producto.compra_maxima+' Unidades','warning');
                }
            } else {
                if(cantidad > 99999){
                    return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto.','warning');
                }
            }
            const change = () => {
                this.updateCantidad(cantidad)
            }
            this.delay(change)
        },
        async updateCantidad(cantidad){
            try {
                const form = {
                    idProducto:this.producto.id_producto,
                    cantidad:cantidad
                }
                const {data} = await Carrito.updateCantidad(form)
                this.producto.total_carrito = data.producto.total_final
               
            } catch (error){
                this.errorCatch(error)
            }
        },
        async eliminarProductoCarrito(){
            try {
                const {data} = await Carrito.eliminarProductoCarrito(this.producto.id_producto)
                this.producto.cant_carrito = 0
                this.notificacion('Eliminado','Producto eliminado del carrito','success')

                this.$usuario.cant_productos_carrito = data.productos_en_carrito;
               
            } catch (error){
                this.errorCatch(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px){
	.carousel-container{
		.slick-slider{
			height: 110px !important;
			.img-carousel{
				height: 110px !important;
				margin: 2px 5px 2px 5px !important;
			}
		}
	}
}

</style>